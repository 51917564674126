<template>
  <div id="nav">
    <!-- <router-link to="/score">Score</router-link> | -->
    <!-- <router-link to="/free">Free</router-link> | -->
    <!-- <router-link to="/multi-couv">Multi Couv</router-link> | -->
    <router-link to="/ipad">Ipad</router-link> |
    <!-- <router-link to="/live-quiz">Live Quiz</router-link> | -->
    <!-- <router-link to="/quote">Quote</router-link> | -->
    <!-- <router-link to="/prog">Prog</router-link> | -->
    <!-- <router-link to="/prog-twitch">prog twitch | </router-link> -->
    <!-- <router-link to="/top-1000">Top 1000 | </router-link> -->
    <router-link to="/couvs-magazines">Couvs magazines |</router-link>
    <router-link to="/instagram"> Instagram |</router-link>
    <router-link to="/notes"> Notes |</router-link>
    <router-link to="/degaine"> Degaine |</router-link>
    <router-link to="/degaine2"> Degaine 2 |</router-link>
    <router-link to="/boutique"> Boutique </router-link>


  </div>
  <div class="container" id="generateur">
    <router-view />
  </div>
</template>

<style>
BODY {
  background-color: rgb(236, 236, 236) !important;
}

.hide-scrollbar {
  overflow: -moz-hidden-unscrollable;
  overflow: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
