import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/free',
    name: 'Free',
    component: () => import('../views/Free.vue')
  },
  {
    path: '/score',
    name: 'Score',
    component: () => import('../views/Score.vue')
  },
  {
    path: '/multi-couv',
    name: 'MultipleCouvertures',
    component: () => import('../views/MultipleCouvertures.vue')
  },
  {
  path: '/ipad',
  name: 'Ipad',
  component: () => import('../views/Ipad.vue')
},
{
  path: '/live-quiz',
  name: 'LiveQuiz',
  component: () => import('../views/LiveQuiz.vue')
},
{
  path: '/quote',
  name: 'Quote',
  component: () => import('../views/Quote.vue')
},
{
  path: '/prog',
  name: 'Prog',
  component: () => import('../views/Prog.vue')
},
{
  path: '/prog-twitch',
  name: 'Prog-twitch',
  component: () => import('../views/Prog-twitch.vue')
},
{
  path: '/top-1000',
  name: 'top-1000',
  component: () => import('../views/top-1000.vue')
},
{
  path: '/couvs-magazines',
  name: 'couvs-magazines',
  component: () => import('../views/CouvsMagazines.vue')
},
{
  path: '/instagram',
  name: 'instagram',
  component: () => import('../views/Instagram.vue')
},
{
  path: '/notes',
  name: 'notes',
  component: () => import('../views/Notes.vue')
},
{
  path: '/degaine',
  name: 'degaine',
  component: () => import('../views/Degaine.vue')
},
{
  path: '/degaine2',
  name: 'degaine2',
  component: () => import('../views/Degaine2.vue')
},
{
  path: '/boutique',
  name: 'boutique',
  component: () => import('../views/Boutique.vue')
},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
